import { EventMerchandise } from '@/types/contentful/event';
import { CURRENCY_SIGNS, DEFAULT_CURRENCY } from '@/utils/i18n';
import { parseIdFromShopify } from '@/utils/shopifyParser';
import { EventItem } from '../../state/queries/eventQueries';
import {
  Cart,
  FlattedCartLine,
  NormalizedCartListItem,
} from '../../types/cart';
import { ensure, removeArrayDuplicates } from '../../utils/array';
import {
  CART_ITEM_TYPE,
  CART_LINE_ATTRIBUTE,
  CART_LINE_INDEX_OF_ATTRIBUTE,
} from './constants';

const cartItemTypeIndex =
  CART_LINE_INDEX_OF_ATTRIBUTE[CART_LINE_ATTRIBUTE.CART_ITEM_TYPE];
const cartCreationDateIndex =
  CART_LINE_INDEX_OF_ATTRIBUTE[CART_LINE_ATTRIBUTE.LINE_CREATION_DATE];

export type CartItemsProductIdsByType = {
  addons: string[];
  events: string[];
};

export const getProductIdsFromCartLines = (
  lines?: Cart['lines'],
): CartItemsProductIdsByType => {
  const cartLineItemsIds: CartItemsProductIdsByType = {
    addons: [],
    events: [],
  };

  lines?.edges.forEach(({ node }) => {
    const { attributes, merchandise } = node;

    if (attributes[cartItemTypeIndex]?.value === CART_ITEM_TYPE.ADDON) {
      cartLineItemsIds.addons.push(parseIdFromShopify(merchandise.product.id));
    } else {
      cartLineItemsIds.events.push(parseIdFromShopify(merchandise.product.id));
    }
  });

  return cartLineItemsIds;
};

export const mergeAndSortCartListByCreationDate = (
  cartLines: Array<NormalizedCartListItem>,
): Array<NormalizedCartListItem> =>
  cartLines.sort((lineA: any, lineB: any) =>
    new Date(lineA[CART_LINE_ATTRIBUTE.LINE_CREATION_DATE]) >
    new Date(lineB[CART_LINE_ATTRIBUTE.LINE_CREATION_DATE])
      ? 1
      : -1,
  );

export const flatCartLinesData = (cart: Cart): Array<FlattedCartLine> =>
  removeArrayDuplicates(
    cart?.lines?.edges.map(({ node }) => ({
      sku: node.merchandise.sku,
      shopifyProductId: parseIdFromShopify(node.merchandise.product.id),
      shopifyVariantId: parseIdFromShopify(node.merchandise.id),
      lineId: node.id,
      quantity: node.quantity,
      price: node.merchandise.priceV2.amount,
      compareAtPrice: node.merchandise.compareAtPriceV2?.amount,
      estimatedCost: {
        subtotalAmount: {
          amount: node.estimatedCost.subtotalAmount.amount,
          currencyCode: node.estimatedCost.subtotalAmount.currencyCode,
        },
        totalAmount: {
          amount: node.estimatedCost.totalAmount.amount,
          currencyCode: node.estimatedCost.totalAmount.currencyCode,
        },
      },
      isAddOnsProduct:
        node?.attributes[cartItemTypeIndex]?.value === CART_ITEM_TYPE.ADDON,
      lineCreationDate: node?.attributes[cartCreationDateIndex]?.value,
    })),
  );

export const getToBeDisplayedMerchandiseData = (
  relatedProducts: EventItem[],
): EventMerchandise[] =>
  relatedProducts
    .map((product) => product.merchandises.map((item) => item))
    .flat();

export const mergeAddonsCartLinesWithContentfulData = (
  cart: Cart,
  relatedAddOnProducts: Array<EventMerchandise>,
): Array<NormalizedCartListItem> => {
  const flattedCartAddOnLines = flatCartLinesData(cart).filter(
    (line) => line.isAddOnsProduct,
  );

  return flattedCartAddOnLines.map(
    ({ shopifyVariantId, ...restOfCartLine }) => {
      const {
        poster,
        title: variantTitle,
        shopifyProductId,
      } = ensure(
        relatedAddOnProducts.find(
          (product) => product.shopifyVariantId === shopifyVariantId,
        ),
      );

      return {
        ...restOfCartLine,
        poster,
        variantTitle,
        shopifyProductId,
        shopifyVariantId,
      };
    },
  );
};
export const mergeEventCartLinesWithContentfulData = (
  cart: Cart,
  relatedProducts: Array<EventItem>,
): Array<NormalizedCartListItem> => {
  const parsedCart = cart;
  const flattedCartLinesData = flatCartLinesData(parsedCart).filter(
    (line) => !line.isAddOnsProduct,
  );
  const normalizedCartList: NormalizedCartListItem[] = [];
  flattedCartLinesData.forEach(
    ({ shopifyProductId, shopifyVariantId, ...restOfCartLine }) => {
      const relatedProduct = relatedProducts.find(
        (product) => product.shopifyProductId === shopifyProductId,
      ); // grab the related product of concerned variant in the cartLines

      if (relatedProduct) {
        const { poster, title: productTitle, variants } = relatedProduct;

        const relatedVariant = variants.find(
          (variant) => variant.shopifyProductVariantId === shopifyVariantId,
        ); // then get the Contentful information of concerned variant

        if (relatedVariant) {
          const {
            title: variantTitle,
            doorsOpen,
            eventStart,
            eventEnd,
            icons,
          } = relatedVariant;

          normalizedCartList.push({
            ...restOfCartLine,
            doorsOpen,
            eventStart,
            eventEnd,
            variantTitle,
            productTitle,
            shopifyProductId,
            shopifyVariantId,
            poster,
            icons,
          } as NormalizedCartListItem);
        }
      }
    },
  );
  return normalizedCartList;
};

export const getCheckoutLineItems = (cart: Cart) =>
  cart?.lines?.edges.map(({ node: { merchandise, quantity } }) => ({
    merchandiseId: merchandise.id,
    quantity,
  }));

export const getCartItemCurrency = (cart: Cart): any =>
  cart.estimatedCost?.totalAmount?.currencyCode || DEFAULT_CURRENCY;

export const formatCartPrice = (
  price?: number | string,
  currencyCode = DEFAULT_CURRENCY,
) => (price ? `${CURRENCY_SIGNS[currencyCode]}${(+price).toFixed(2)}` : '-');
